import React from 'react'
import NavigationInterior from '../components/navigation-interior'
import PersonalTitle from '../components/site-title'
import Footer from '../components/footer'

const PressPage = ( props ) => {
    return (
        <div>
            <div key="two" className="single-post generic-container">
                <PersonalTitle key="four"></PersonalTitle>
                <div key ="three" className="single-post-inner">
                    <p>Press</p>
                </div>
                <NavigationInterior key="six"></NavigationInterior>
            </div>
            <Footer key="seven"></Footer>
        </div>
    )
};

export default PressPage